import React from 'react'
import SupportTicket from '../../../components/private/supportTickets'

const SupportTickets = () => {
    return (
        <section className="support-tickets w-full mt-[69px]">
            <div className=''>
                {/* Support Ticket */}
                <SupportTicket />
            </div>
        </section>
    )
}

export default SupportTickets