import {Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import './styles.scss';
import {inputFieldRule, toastAlert, toTitleCase} from '../../../utils';
import {CustomDispatch} from '../../../helpers';
import {useSelector} from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import Icons from '../../../theme/icons';
import SupportTicketTable from '../supportTicketTable';
import {
  addSupportTicketRequest,
  deleteSupportTicketRequest,
  editSupportTicketRequest,
  getSupportTicketRequest,
} from '../../../redux/slicers/supportTicket';
import {useNavigate} from 'react-router-dom';

const SupportTicket = () => {
  //STATES
  const [isSendWelcomeNotice, setIsSendWelcomeNotice] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const navigate = useNavigate();

  //REDUX DATA
  const user = useSelector(state => state.user.data);
  console.log('User', user);
  //CUSTOM DISPATCH
  const [getSupportTicket, supportTicketLoader] = CustomDispatch(
    getSupportTicketRequest,
  );
  const [addSupportTicket, addsupportTicketLoader] = CustomDispatch(
    addSupportTicketRequest,
  );
  const [editSupportTicket, editSupportTicketLoader] = CustomDispatch(
    editSupportTicketRequest,
  );
  const [deleteSupportTicket, deleteSupportTicketLoader] = CustomDispatch(
    deleteSupportTicketRequest,
  );

  const [form] = Form.useForm();
  const [editUserForm] = Form.useForm();
  const [viewUserForm] = Form.useForm();

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Please Enter a valid email.',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  //HANDLERS
  const handleFinish = vals => {
    const payload = {
      user: user.id,
      company: user.companyId,
      ticket_type: vals.ticket_type,
      subject: vals.subject,
      approved_by: user.id, // might be change
      description: vals.description,
      email: vals.email,
      phone: vals.phone,
    };
    console.log('Add ST Paylod', payload);
    addSupportTicket({
      payload: payload,
      logic: res => {
        toastAlert('Support Ticket added successfully');
        form.resetFields();
        setIsSendWelcomeNotice(false);
        getSupportTicket();
      },
    });
  };

  const handleEditFinish = vals => {
    const updatedPayload = {
      user: user.id,
      company: user.companyId,
      ticket_type: vals.ticket_type,
      subject: vals.subject,
      approved_by: user.id, // might be change
      description: vals.description,
      email: vals.email,
      phone: vals.phone,
      // status: vals.status,
    };

    console.log('Updated Payload ST', updatedPayload, ' Id', selectedDataId);
    editSupportTicket({
      payload: updatedPayload,
      pathParams: selectedDataId,
      logic: res => {
        toastAlert('Support Ticket updated successfully');
        form.resetFields();
        setIsSendWelcomeNotice(false);
        setIsEdit(false);
        getSupportTicket();
      },
    });
  };

  //HOOKS
  useEffect(() => {
    getSupportTicket();
    console.log('GET ST UseEffect', getSupportTicket());
  }, []);

  const handleShowDetails = data => {
    setShowDetails(true);
    console.log('Details', data);
    setSelectedDetails(data);
    viewUserForm.setFieldsValue({
      subject: data.subject,
      email: data.email,
      phone: data.phone,
      ticket_type: data.ticket_type,
      description: data.description,
      status: data.status,
    });
  };

  const handleEdit = data => {
    setIsEdit(true);
    console.log('Edit Table Data ', data);

    if (!data) return;
    setSelectedDataId(data?.ticket_id);
    setIsEdit(true);
    editUserForm.setFieldsValue({
      subject: data.subject,
      email: data.email,
      phone: data.phone,
      ticket_type: data.ticket_type,
      description: data.description,
      status: data.status,
    });
  };

  const handleClose = () => {
    form.resetFields();
    setIsSendWelcomeNotice(false);
    setIsEdit(false);
    setSelectedDataId(null);
    setShowDetails(false);
  };

  const handleDelete = id => {
    console.log('ID to Delete ST', id);
    deleteSupportTicket({
      pathParams: id,
      logic(res) {
        toastAlert('Support Ticket deleted successfully');
        getSupportTicket();
      },
    });
  };

  // TICKET TYPE
  const ticketType = [
    {id: 1, name: 'Bug'},
    {id: 2, name: 'Feature'},
    {id: 3, name: 'Request'},
  ];

  return (
    <section className="bg-[#EBF1F4] mt-[69px] relative">
      <div className="absolute top-[0] w-full flex items-center justify-between pl-7 pr-20 py-[11px] bg-grey-100 border-b-[1px] border-solid border-grey-200 z-[1]">
        <div className="flex items-center justify-start gap-[25px]">
          <Icons.LeftChevron
            className="cursor-pointer icon"
            onClick={() => navigate('/')}
          />
          <p className="font-h2 uppercase ">Support Ticket</p>
        </div>
      </div>
      <div className="md:mx-10 mx-4 pt-[70px]">
        <div>
          {/* <p className="font-h4 mb-5 pt-5 ">Support Ticket</p> */}
          <Form
            form={form}
            validateMessages={validateMessages}
            onFinish={handleFinish}>
            <Row
              gutter={[10, 0]}
              className="p-2 border-[1px] border-[#D5DFE6] rounded-md max-w-full mr-0 ml-0 m-0">
              <Col span={24}>
                <p className="font-subtext text-[14px] mb-3">
                  New Ticket Information
                </p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Subject</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Subject',
                    isRequired: true,
                  })}
                  shouldUpdate
                  name={'subject'}>
                  <Input className="text-[12px]" placeholder="Subject" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Email</p>
                <Form.Item
                  name={'email'}
                  rules={inputFieldRule({
                    name: 'Email',
                    isRequired: true,
                    isEmail: true,
                  })}>
                  <Input
                    className="text-[12px]"
                    type="email"
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Phone</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Phone',
                    isRequired: true,
                  })}
                  name={'phone'}>
                  <PhoneInput
                    onlyCountries={['us']}
                    disableDropdown
                    country="us"
                    // countryCodeEditable={false}
                    specialLabel=""
                    placeholder="Phone"
                    inputClass="text-[12px] rounded-[6px] h-[32px] w-full px-[11px] bg-[#fff] border border-[#d9d9d9] placeholder:text-[#00000040]"
                  />

                  {/* <Input className="text-[12px]" placeholder="Phone" /> */}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Ticket Type</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Ticket Type',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'ticket_type'}>
                  <Select
                    options={ticketType.map(ticket => ({
                      label: toTitleCase(ticket.name),
                      value: ticket.name,
                    }))}
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Ticket Type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                <p className="font-subtext text-[12px] mb-1">Description</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Description',
                    isRequired: true,
                  })}
                  name={'description'}>
                  <Input.TextArea
                    className="text-[12px]"
                    placeholder="Enter description here..."
                    rows={4} // Number of visible lines
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div className="flex w-[200px] justify-center items-center">
                  <Button
                    htmlType="submit"
                    loading={addsupportTicketLoader}
                    className="disabled:bg-transparent w-full uppercase text-[10px] bg-blue-300 text-white-100 rounded-md hover:bg-opacity-70 py-5 flex items-center justify-center">
                    <p className="text-[13px]">Add</p>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          <p className="font-h4 mb-5 mt-5">Tickets</p>
          <SupportTicketTable
            selected={selected}
            setSelected={setSelected}
            handleDelete={handleDelete}
            setViewDetails={handleShowDetails}
            setIsEdit={handleEdit}
            loading={supportTicketLoader}
            deleteLoader={deleteSupportTicketLoader}
          />
        </div>
        {/* )} */}
      </div>

      {/* Eye Modal */}
      <Modal
        width={750}
        open={showDetails}
        destroyOnClose
        footer={null}
        centered
        className="support-ticket-modal"
        onCancel={() => {
          handleClose();
          setShowDetails(false);
          setSelectedDetails(null);
        }}
        maskClosable={false}>
        {/* <p className="font-h4 mb-2 font-bold ml-2">
          {selectedDetails?.subject || 'N/A'}
        </p>
        <p className=" mb-2">{selectedDetails?.description || 'N/A'}</p> */}
        <div className="mb-5">
          {/* <p className="font-h4 mb-5 pt-5">Ticket Settings</p> */}
          <Form
            form={viewUserForm}
            className="px-2"
            validateMessages={validateMessages}
            onFinish={handleEditFinish}>
            <Row gutter={[50, 10]}>
              <Col span={24}>
                <p className="font-subtext text-[14px]">Ticket Information</p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Subject</p>
                <Form.Item name={'subject'}>
                  <Input placeholder="Subject" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Email</p>
                <Form.Item
                  name={'email'}
                  rules={[
                    {
                      type: 'email',
                    },
                  ]}>
                  <Input type="email" placeholder="Email" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Phone</p>
                <Form.Item name={'phone'}>
                  <PhoneInput
                    onlyCountries={['us']}
                    disableDropdown
                    country="us"
                    specialLabel=""
                    placeholder="Phone"
                    disabled
                    inputClass="text-[12px] rounded-[6px] h-[32px] w-full px-[11px] bg-[#fff] border border-[#d9d9d9] placeholder:text-[#00000040]"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Ticket Type</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Ticket Type',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'ticket_type'}>
                  <Select
                    options={ticketType.map(ticketType => ({
                      label: toTitleCase(ticketType.name),
                      value: ticketType.name,
                    }))}
                    disabled
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Ticket Type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Description</p>
                <Form.Item name={'description'}>
                  <Input.TextArea
                    placeholder="Enter description here..."
                    rows={4}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Status</p>
                <Form.Item name={'status'}>
                  <Input placeholder="Subject" disabled />
                </Form.Item>
              </Col>{' '}
            </Row>
          </Form>
        </div>
      </Modal>

      {/* Edit Modal */}
      <Modal
        width={750}
        open={isEdit}
        destroyOnClose
        footer={null}
        centered
        className="support-ticket-modal"
        onCancel={() => {
          handleClose();
          setIsEdit(false);
          setSelectedDataId(null);
        }}
        maskClosable={false}>
        <div className="mb-5">
          <p className="font-h4 mb-5 pt-5">Ticket Settings</p>
          <Form
            form={editUserForm}
            className="px-2"
            validateMessages={validateMessages}
            onFinish={handleEditFinish}>
            <Row gutter={[50, 10]}>
              <Col span={24}>
                <p className="font-subtext text-[14px]">Ticket Information</p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Subject</p>
                <Form.Item name={'subject'}>
                  <Input placeholder="Subject" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Email</p>
                <Form.Item
                  name={'email'}
                  rules={[
                    {
                      type: 'email',
                    },
                  ]}>
                  <Input type="email" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Phone</p>
                <Form.Item name={'phone'}>
                  <PhoneInput
                    onlyCountries={['us']}
                    disableDropdown
                    country="us"
                    // countryCodeEditable={false}
                    specialLabel=""
                    placeholder="Phone"
                    inputClass="text-[12px] rounded-[6px] h-[32px] w-full px-[11px] bg-[#fff] border border-[#d9d9d9] placeholder:text-[#00000040]"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Ticket Type</p>
                <Form.Item
                  rules={inputFieldRule({
                    name: 'Ticket Type',
                    isRequired: true,
                    isWhiteSpace: false,
                  })}
                  name={'ticket_type'}>
                  <Select
                    options={ticketType.map(ticketType => ({
                      label: toTitleCase(ticketType.name),
                      value: ticketType.name,
                    }))}
                    // options={[{label: 'Admin', value: 1}]}
                    placeholder="Select Ticket Type"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Description</p>
                <Form.Item name={'description'}>
                  <Input.TextArea
                    placeholder="Enter description here..."
                    rows={4}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Status</p>
                <Form.Item name={'status'}>
                  <Input placeholder="Status" disabled />
                </Form.Item>
              </Col>{' '}
              {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className="font-h4 mb-2">Status</p>
                <Form.Item name={'status'}>
                  {user?.role === 1 ? (
                    <Select placeholder="Select Status">
                      <Select.Option value="Pending">Pending</Select.Option>
                      <Select.Option value="In Review">In Review</Select.Option>
                      <Select.Option value="Complete">Complete</Select.Option>
                      <Select.Option value="Rejected">Rejected</Select.Option>
                    </Select>
                  ) : (
                    <Input placeholder="Status" disabled />
                  )}
                </Form.Item>
              </Col>{' '} */}
            </Row>

            <div className="flex items-center justify-end mt-10 gap-3 sm:flex-nowrap flex-wrap">
              <Button
                onClick={() => {
                  setIsEdit(false);
                  setSelectedDataId(null);
                }}
                className="cancel-btn sm:w-[100px] w-full text-textColor-100 uppercase text-[10px]  disabled:bg-transparent bg-transparent rounded-md  hover:bg-transparent ">
                Cancel
              </Button>
              <Button
                htmlType="submit"
                loading={editSupportTicketLoader}
                className="disabled:bg-transparent sm:w-[100px] w-full uppercase text-[10px] bg-blue-300 text-white-100  rounded-md  hover:bg-opacity-70">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </section>
  );
};

export default SupportTicket;
