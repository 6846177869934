import {take, put, call, fork} from 'redux-saga/effects';
import {
  getSupportTicketRequest,
  getSupportTicketSuccess,
  addSupportTicketRequest,
  addSupportTicketSuccess,
  deleteSupportTicketRequest,
  deleteSupportTicketSuccess,
  editSupportTicketRequest,
  editSupportTicketSuccess,
} from '../slicers/supportTicket';
import {ALERT_TYPES} from '../../constants';
import {
  ADD_SUPPORT_TICKET,
  callRequest,
  DELETE_SUPPORT_TICKET,
  EDIT_SUPPORT_TICKET,
  GET_SUPPORT_TICKET,
} from '../../config/web-service';
import {toastAlert} from '../../utils';
import {manipulateMembersList} from '../../data-manipulator/user';

//Get Support Ticket
function* getSupportTicket() {
  console.log('Called GET ST');
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getSupportTicketRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_SUPPORT_TICKET,
      });
      if (response.response_code === '200') {
        console.log('Get 200 ST');
        responseCallback?.(true, response);

        yield put(
          getSupportTicketSuccess(response.response_data), // mainpulate here
        );
      } else {
        console.log('Get Else ST');
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//Add Support Ticket
function* addSupportTicket() {
  console.log('Called Add ST');
  while (true) {
    const {
      payload: {responseCallback, payload},
    } = yield take(addSupportTicketRequest.type);
    try {
      const response = yield call(callRequest, {
        url: ADD_SUPPORT_TICKET,
        payload,
      });
      if (response.response_code === '200') {
        console.log('Add 200 ST');
        responseCallback?.(true, response);
        yield put(addSupportTicketSuccess(response.response_data));
      } else {
        console.log('Add Else ST');
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//Delete Support Ticket
function* deleteSupportTicket() {
  console.log('Called Delete ST');
  while (true) {
    const {
      payload: {responseCallback, pathParams},
    } = yield take(deleteSupportTicketRequest.type);
    try {
      const response = yield call(callRequest, {
        url: DELETE_SUPPORT_TICKET,
        pathParams: pathParams,
      });
      if (response.response_code === '200') {
        console.log('Delete 200 ST');
        responseCallback?.(true, response);
        yield put(deleteSupportTicketSuccess(response.response_data));
      } else {
        console.log('Delete Else ST');
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//Edit Support Ticket
function* editSupportTicket() {
  console.log('Called Edit ST');
  while (true) {
    const {
      payload: {responseCallback, pathParams, payload},
    } = yield take(editSupportTicketRequest.type);
    try {
      const response = yield call(callRequest, {
        url: EDIT_SUPPORT_TICKET,
        pathParams,
        payload,
      });
      if (response.response_code === '200') {
        console.log('Edit 200 ST');
        console.log(response, 'responseCallback');
        responseCallback?.(true, response);

        yield put(editSupportTicketSuccess(response.response_data));
      } else {
        console.log('Edit Else ST');
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(getSupportTicket);
  yield fork(addSupportTicket);
  yield fork(deleteSupportTicket);
  yield fork(editSupportTicket);
}
