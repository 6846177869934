import {combineReducers} from '@reduxjs/toolkit';

import general from './general';
import user from './user';
import form from './form';
import members from './members';
import offline from './offlineData';
import rules from './rules';
import optionalSteps from './optionalSteps';
import supportTicket from './supportTicket';

export default combineReducers({
  optionalSteps,
  general,
  user,
  form,
  members,
  offline,
  rules,
  supportTicket,
});
