import React, {useState} from 'react';
import {Button, Table} from 'antd';
import './styles.scss';
import {useSelector} from 'react-redux';
import Icons from '../../../theme/icons';
import {ClipLoader} from 'react-spinners';
import {Eye} from 'lucide-react';

const SupportTicketTable = ({
  selected,
  setSelected,
  setIsEdit,
  handleDelete,
  deleteLoader,
  loading,
  setViewDetails,
}) => {
  const supportTicket = useSelector(state => state.supportTicket.tickets);
  console.log('ST in Table', supportTicket);
  const users = [
    {id: 1, name: 'Alice'},
    {id: 2, name: 'Bob'},
  ];
  const user = useSelector(state => state.user.data);
  console.log('User in ST table', user);
  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.details.localeCompare(b.details),
      width: 200,
      ellipsis: true,
    },
    {
      title: 'Username',
      dataIndex: 'user_name',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.details.localeCompare(b.details),
      width: 200,
      ellipsis: true,
    },
    {
      title: 'Ticket Type',
      dataIndex: 'ticket_type',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.ticket_type.localeCompare(b.ticket_type),
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 120,
      ellipsis: true,
    },
    // {
    //   title: 'User',
    //   dataIndex: 'user',
    //   render: userId =>
    //     users.find(user => user.id === userId)?.name || 'Unknown',
    //   sorter: (a, b) => {
    //     const userA = users.find(user => user.id === a.user)?.name || '';
    //     const userB = users.find(user => user.id === b.user)?.name || '';
    //     return userA.localeCompare(userB);
    //   },
    //   width: 120,
    //   ellipsis: true,
    // },
    {
      title: '',
      dataIndex: '',
      width: 100,
      render: data => (
        <div className="flex items-center space-x-2">
          <Eye
            height={16}
            width={16}
            className="cursor-pointer"
            stroke="#004078"
            onClick={() => setViewDetails(data)}
          />
          <Icons.EditIcon
            height={16}
            width={16}
            className="cursor-pointer"
            onClick={() => setIsEdit(data)}
          />
        </div>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys); // Ensure only one row is selected
    setSelected(newSelectedRowKeys?.[0]); // Update parent component's selected state
  };

  const rowSelection = {
    type: 'radio', // Use radio selection for single row selection
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="rounded-md">
      <div className="p-[10px] rounded-t-[8px] bg-white-100 flex items-center justify-between gap-[10px]">
        <span className="font-table flex items-center justify-start gap-[10px]">
          <p className="font-medium">Total:</p> {supportTicket?.length}
        </span>
        {deleteLoader ? (
          <ClipLoader size={10} color="#004078" />
        ) : selectedRowKeys?.length !== 0 ? (
          <Icons.DeleteIcon
            height={16}
            width={16}
            className="cursor-pointer"
            onClick={() => handleDelete(selectedRowKeys[0])}
          />
        ) : (
          <Icons.DeleteIconDisabled
            height={16}
            width={16}
            className="cursor-not-allowed"
          />
        )}
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={supportTicket?.map(ticket => ({
          ...ticket,
          key: ticket.ticket_id,
        }))}
        pagination={false}
        scroll={{y: 150, x: 530}}
        className="support-ticket-table"
        loading={loading}
      />
    </div>
  );
};
export default SupportTicketTable;
