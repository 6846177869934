import {createSlice} from '@reduxjs/toolkit';

const OptionalReducer = createSlice({
  name: 'optionalSteps',
  initialState: {
    optionalSteps: [],
  },
  reducers: {
    // setOptionalSteps: (state, action) => {
    //   console.log('Action Pay ', action.payload);
    //   if (!state.optionalSteps.includes(action.payload)) {
    //     state.optionalSteps.push(action.payload);
    //   }
    // },
    setOptionalSteps: (state, action) => {
      console.log('Action Payload:', action.payload);

      // Check if payload is an array
      if (Array.isArray(action.payload)) {
        console.log('first');
        action.payload.forEach(number => {
          if (!state.optionalSteps.includes(number)) {
            state.optionalSteps.push(number); // Add unique numbers from array
          }
          console.log('number in array', number, 'state', state.optionalSteps);
        });
      } else if (typeof action.payload === 'number') {
        // Handle single number payload
        if (!state.optionalSteps.includes(action.payload)) {
          state.optionalSteps.push(action.payload); // Add unique number
        }
      } else {
        console.warn('Unexpected payload type:', typeof action.payload);
      }
    },

    blankOptionalSteps: state => {
      state.optionalSteps = [];
    },
  },
});

export const {setOptionalSteps, blankOptionalSteps} = OptionalReducer.actions;

export default OptionalReducer.reducer;
