import React, {useState} from 'react';
import {Button, Table} from 'antd';
import './styles.scss';
import {useSelector} from 'react-redux';
import Icons from '../../../theme/icons';
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
import {toTitleCase} from '../../../utils';
import {ClipLoader} from 'react-spinners';

const MembersTable = ({
  selected,
  setSelected,
  setIsEdit,
  handleDelete,
  deleteLoader,
  loading,
}) => {
  const [showPassword, setShowPassword] = useState(null);
  const roles = useSelector(state => state.members.roles);
  const columns = [
    {
      title: 'Level',
      width: '140px',
      render: data => (
        <p className="font-table">
          {toTitleCase(roles?.find(x => x?.id === data?.level)?.name)}
        </p>
      ),
      sorter: (a, b) => a.level - b.level,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '100px',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      width: '100px',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    // {
    //   title: 'PIN',
    //   width: '130px',

    //   render: data => {
    //     const getPasswordDisplay = () => {
    //       return showPassword === data?.id ? data?.password : '●'.repeat(8);
    //     };
    //     const togglePasswordVisibility = () => {
    //       if (showPassword === data?.id) {
    //         setShowPassword(null);
    //         return;
    //       }
    //       setShowPassword(data?.id);
    //     };
    //     return (
    //       <div className="flex items-center justify-between gap-1">
    //         <p className="font-table">{getPasswordDisplay()}</p>
    //         <Button
    //           type="link"
    //           icon={
    //             showPassword === data?.id ? (
    //               <EyeInvisibleOutlined />
    //             ) : (
    //               <EyeOutlined />
    //             )
    //           }
    //           onClick={togglePasswordVisibility}
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Added On',
      dataIndex: 'addedOn',
      width: '100px',
      render: text => <p className="font-table">{text}</p>,
      sorter: (a, b) => new Date(a.addedOn) - new Date(b.addedOn),
    },
    {
      title: '',
      dataIndex: '',
      width: '40px',
      render: data => {
        return (
          <Icons.EditIcon
            height={16}
            width={16}
            className="cursor-pointer"
            onClick={() => setIsEdit(data)}
          />
        );
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const members = useSelector(state => state.members.members);

  // Handle selection change
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys); // Update selectedRowKeys
    // setSelected(members?.find(x => x.id === newSelectedRowKeys?.[0])); // Update selected IDs
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys: selectedRowKeys, // Ensure selectedRowKeys is correctly passed
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  return (
    <div className="rounded-md">
      <div className="p-[10px] rounded-t-[8px] bg-white-100 flex items-center justify-between gap-[10px]">
        <span className="font-table flex items-center justify-start gap-[10px]">
          <p className="font-medium">Total:</p> {members?.length}
        </span>
        {deleteLoader ? (
          <ClipLoader size={10} color="#004078" />
        ) : selectedRowKeys?.length !== 0 ? (
          <Icons.DeleteIcon
            height={16}
            width={16}
            className="cursor-pointer"
            onClick={() => handleDelete(selectedRowKeys[0])}
          />
        ) : (
          <Icons.DeleteIconDisabled
            height={16}
            width={16}
            className="cursor-not-allowed"
          />
        )}
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={members ?? []}
        pagination={false}
        scroll={{y: 150, x: 530}}
        className="members-table"
        loading={loading}
      />
    </div>
  );
};

export default MembersTable;
