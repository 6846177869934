import qs from 'qs';
import _ from 'lodash';
import ApiHandler from '../services/api-handler';
import {getCurrentAccessToken, getCurrentCompanyId} from '../utils';

export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = 'Network failed. Aborted request.';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ERROR_SOMETHING_WENT_WRONG =
  'Something went wrong, Please try again later';
export const ERROR_API_NOT_FOUND = 'Api not found, Please try again later';

export const ERROR_NETWORK_NOT_AVAILABLE =
  'Please connect to the working Internet';

export const ERROR_ACCOUNT_BLOCKED =
  'Either your account is blocked or deleted';

export const ERROR_TOKEN_EXPIRE = 'Session Expired, Please login again!';

export const REQUEST_TYPE = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put',
  PATCH: 'patch',
};

// AUTH ROUTES
export const USER_LOGIN = {
  route: 'login',
  access_token_required: false,
  type: REQUEST_TYPE.POST,
};

export const USER_LOGOUT = {
  route: 'logout',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

//PRIVATE ROUTES
export const GET_PROCEDURES = {
  route: 'dashboard/procedures',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_TECHNICIANS = {
  // route: 'technician', --Swagger API
  route: 'user/technicians',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_RN = {
  // route: 'technician', --Swagger API
  route: 'user/authorized',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_FORM_PAYLOAD = {
  route: 'procedure',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_FORM_UI = {
  // route: 'ui/procedure', --Swagger API
  route: 'procedure/ui',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const SUBMIT_FORM = {
  route: 'procedure',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const SEARCH_REPORT = {
  route: 'report/',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_PDF_JSON = {
  route: 'report/procedure',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_SUPPORT_TICKET = {
  route: 'support/tickets',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_SUPPORT_TICKET = {
  route: 'support/tickets/',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const DELETE_SUPPORT_TICKET = {
  route: 'support/tickets',
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const EDIT_SUPPORT_TICKET = {
  route: 'support/tickets',
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_MEMBERS = {
  route: 'user/', // user/
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const ADD_MEMBER = {
  route: 'user/', // user/
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const DELETE_MEMBER = {
  route: 'user',
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};

export const EDIT_MEMBER = {
  route: 'user',
  access_token_required: true,
  type: REQUEST_TYPE.PUT,
};

export const GET_ROLES = {
  route: 'get/roles',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_SHIFTS = {
  route: 'get/shifts',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_COMPANIES = {
  route: 'get/companies',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const GET_CARRIERS = {
  route: 'get/email_to_sms',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_COMPLIANCE_RULES = {
  route: 'alert/compliance-scheduler',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const CREATE_COMPLIANCE_RULE = {
  route: 'alert/compliance-scheduler/',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const EDIT_COMPLIANCE_RULE = {
  route: 'alert/compliance-scheduler',
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};
export const DELETE_COMPLIANCE_RULE = {
  route: 'alert/compliance-scheduler',
  access_token_required: true,
  type: REQUEST_TYPE.DELETE,
};
export const VERIFY_PIN = {
  route: 'user/check-pin',
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};
export const GET_TOTAL_CHLORINE = {
  route: 'get/total-chlroine',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_WATER_SOFTENER = {
  route: 'get/water-softener',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_MDL_RESULT = {
  route: 'get/result',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_REASON_CODE = {
  route: 'get/reason-code',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};
export const GET_SALT_LEVEL = {
  route: 'get/salt-level',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_TODAYS_LOGS = {
  route: 'procedure/todays',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const UPDATE_TODAYS_LOGS = {
  route: 'procedure/todays',
  access_token_required: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_MACHINE_STATUS = {
  route: 'get/machine_status',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_STATUS = {
  route: 'get/status',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_VERSIONS = {
  route: 'procedure/versions',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const GET_AUTHORIZED_TECHNICIANS = {
  route: 'user/technicians_authorized',
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

export const callRequest = async ({
  url,
  payload = {},
  queryParams,
  pathParams,
  header = {},
  baseURL = BASE_URL,
  cacheBusting = false,
}) => {
  let _header = {...header};
  if (url.access_token_required) {
    const _access_token = getCurrentAccessToken();
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
          // CompanyID: 2,
          CompanyID: getCurrentCompanyId(),
        },
      };
    }
  }
  let _url = url.route;
  if (pathParams) {
    _url = `${url.route}/${pathParams}`;
  }

  if (queryParams && !_.isEmpty(queryParams)) {
    _url = `${_url}?${
      queryParams instanceof Object ? qs.stringify(queryParams) : queryParams
    }`;
  }

  if (cacheBusting) _url += `?t${Date.now()}`;

  let response = await ApiHandler(url.type, _url, payload, _header, baseURL);
  return response;
};
