import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {
  ACCESS_TYPES,
  DASHBOARD_ROUTE,
  FORM_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
} from '../constants';
import {PrivateRoute, PublicRoute, AuthRoute} from '../config/routes-handling';
import {
  ComplianceRules,
  Dashboard,
  Error,
  FormPage,
  Home,
  Login,
  OptionalFormPage,
  Submissions,
} from '../modules';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import FormPageTest from '../modules/private/FormPageTest';
import SupportTickets from '../modules/private/SupportTickets';

const renderRouteSharedLayout = (title, description, access, component) => (
  <React.Fragment>
    <HelmetProvider>
      <Helmet>
        <title>
          {title ? `${title} |` : ''} {process.env.REACT_APP_WEB_TITLE}
        </title>
        {description && <meta name="description" content={description} />}
      </Helmet>
      {access === ACCESS_TYPES.AUTH ? (
        <AuthRoute> {component}</AuthRoute>
      ) : access === ACCESS_TYPES.PRIVATE ? (
        <PrivateRoute>{component}</PrivateRoute>
      ) : (
        <PublicRoute>{component}</PublicRoute>
      )}
    </HelmetProvider>
  </React.Fragment>
);

const PageRoutes = () => {
  // CONST VALS
  const location = useLocation();

  const ROUTES = [
    // PUBLIC ROUTES
    {
      route: HOME_ROUTE,
      title: 'Home',
      description: '',
      access: ACCESS_TYPES.PUBLIC,
      component: <Home />,
    },

    // AUTH ROUTES
    {
      route: LOGIN_ROUTE,
      title: 'Login',
      description: '',
      access: ACCESS_TYPES.AUTH,
      component: <Login />,
    },
    // PRIVATE ROUTE
    {
      route: DASHBOARD_ROUTE,
      title: 'Dashboard',
      description: '',
      access: ACCESS_TYPES.PRIVATE,
      component: <Dashboard />,
    },
    {
      route: FORM_ROUTE,
      title: 'Form',
      description: '',
      access: ACCESS_TYPES.PRIVATE,
      component: <FormPage />,
    },
    {
      route: '/compliance-rules',
      title: 'Compliance Rules',
      description: '',
      access: ACCESS_TYPES.PRIVATE,
      component: <ComplianceRules />,
    },
    {
      route: '/today-submissions',
      title: 'Today Submissions',
      description: '',
      access: ACCESS_TYPES.PRIVATE,
      component: <Submissions />,
    },
    {
      route: '/optional-form/:id',
      title: 'Form',
      description: '',
      access: ACCESS_TYPES.PRIVATE,
      component: <OptionalFormPage />,
    },
    {
      route: '/support-tickets',
      title: 'Support Tickets',
      description: '',
      access: ACCESS_TYPES.PRIVATE,
      component: <SupportTickets />,
    },
  ];

  // HOOKS
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        {ROUTES.map((item, index) => (
          <Route
            path={item.route}
            element={renderRouteSharedLayout(
              item.title,
              item.description,
              item.access,
              item.component,
            )}
            key={index}
          />
        ))}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
