import {createSlice} from '@reduxjs/toolkit';

const SupportTicket = createSlice({
  name: 'supportTicket',
  initialState: {
    tickets: [],
  },
  reducers: {
    getSupportTicketRequest() {},
    getSupportTicketSuccess(state, action) {
      state.tickets = action.payload;
    },

    addSupportTicketRequest() {},
    addSupportTicketSuccess(state, action) {},

    deleteSupportTicketRequest() {},
    deleteSupportTicketSuccess(state, action) {},

    editSupportTicketRequest() {},
    editSupportTicketSuccess(state, action) {},
  },
});

export const {
  getSupportTicketRequest,
  getSupportTicketSuccess,
  addSupportTicketRequest,
  addSupportTicketSuccess,
  deleteSupportTicketRequest,
  deleteSupportTicketSuccess,
  editSupportTicketRequest,
  editSupportTicketSuccess,
} = SupportTicket.actions;

export default SupportTicket.reducer;
