import {Button, Col, Form, Input, Modal, Row, Select, TimePicker} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import {CustomDispatch} from '../../../helpers';
import {getProceduresRequest} from '../../../redux/slicers/general';
import {
  createComplianceRuleRequest,
  editComplianceRuleRequest,
} from '../../../redux/slicers/rules';
import {getUserDataRequest} from '../../../redux/slicers/user';
import {
  getCurrentCompanyId,
  inputFieldRule,
  toastAlert,
  toTitleCase,
} from '../../../utils';
import './styles.scss';

const CreateComplianceRuleModal = ({
  open,
  handleClose,
  successHandler,
  RULE_TYPES,
  INTERVALS,
  selectedData,
}) => {
  console.log(selectedData, 'selectedData');
  //CONST VALS
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Please Enter a valid email.',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const [form] = Form.useForm();
  const isEdit = selectedData !== null;
  //REDUX DATA
  const {data, companies} = useSelector(state => state.user);
  const {procedures} = useSelector(state => state.general);

  //CUSTOM DISPATCH
  const [getUserData, isLoading] = CustomDispatch(getUserDataRequest);
  const [getProcedures, proceduresLoader] =
    CustomDispatch(getProceduresRequest);
  const [createRule, createRuleLoader] = CustomDispatch(
    createComplianceRuleRequest,
  );
  const [editRule, editRuleLoader] = CustomDispatch(editComplianceRuleRequest);

  //HANDLERS
  const handleFinish = vals => {
    console.log(vals);
    if (isEdit) {
      editRule({
        payload: {
          ...vals,
          rule_value: dayjs(vals?.rule_value).format('HH:mm:ss'),
          status: 0,
          company: getCurrentCompanyId(),
        },
        pathParams: `${selectedData?.id}/`,
        logic: res => {
          toastAlert('Rule Updated successfully');
          form.resetFields();
          successHandler();
        },
      });
      return;
    }
    createRule({
      payload: {
        ...vals,
        rule_value: dayjs(vals?.rule_value).format('HH:mm:ss'),
        status: 0,
        company: getCurrentCompanyId(),
      },
      // pathParams: data?.id,
      logic: res => {
        toastAlert('Rule Created successfully');
        form.resetFields();
        successHandler();
      },
    });
  };

  const closeHandler = () => {
    form.resetFields();
    handleClose();
  };

  //HOOKS
  useEffect(() => {
    if (open) {
      getUserData({
        pathParams: data?.id,
      });
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      getProcedures();
    }
  }, [open]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        form: selectedData?.form,
        rule_type: selectedData?.rule_type,
        rule_value: dayjs(selectedData?.rule_value, 'HH:mm:ss'),
        alert_message: selectedData?.alert_message,
        // company: selectedData?.company,
        schedule_interval: selectedData?.schedule_interval,
      });
    }
  }, [selectedData]);

  return (
    <Modal
      width={750}
      open={open}
      closeIcon={false}
      destroyOnClose
      footer={null}
      centered
      className="create-rule-modal">
      <p className="font-h4 mb-5">Create Compliance Rule</p>
      {isLoading ? (
        <div className="flex items-center justify-center h-[300px]">
          <ClipLoader color="#123abc" size={30} />
        </div>
      ) : (
        <Form form={form} className="px-2" onFinish={handleFinish}>
          <Row gutter={[50, 10]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Form ID</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Form',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'form'}>
                <Select
                  options={procedures?.map(procedure => ({
                    label: toTitleCase(procedure.title),
                    value: procedure.form_id,
                  }))}
                  loading={proceduresLoader}
                  placeholder="Select Form"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Rule Type</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Rule Type',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'rule_type'}>
                <Select
                  options={RULE_TYPES}
                  placeholder="Select Rule Type"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Rule Value</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Rule Value',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'rule_value'}>
                <TimePicker
                  use12Hours
                  format="h:mm:A"
                  needConfirm
                  placeholder="Select Time"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                />
              </Form.Item>
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Schedule Interval</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Interval',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'schedule_interval'}>
                <Select
                  options={INTERVALS}
                  placeholder="Select Interval"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <p className="font-h4 mb-2">Alert Message</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Alert Message',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'alert_message'}>
                <Input.TextArea
                  className="custom-input"
                  placeholder={'Enter Alert Message'}
                  maxLength={150}
                  minLength={10}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Company ID</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Company',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'company'}>
                <Select options={companies} placeholder="Select Company" />
              </Form.Item>
            </Col> */}
          </Row>

          <div className="flex items-center justify-end mt-10 gap-3 sm:flex-nowrap flex-wrap">
            <Button
              onClick={closeHandler}
              className="cancel-btn sm:w-[100px] w-full text-textColor-100 uppercase text-[10px]  disabled:bg-transparent bg-transparent rounded-md  hover:bg-transparent ">
              Cancel
            </Button>
            <Button
              htmlType="submit"
              loading={isEdit ? editRuleLoader : createRuleLoader}
              className="disabled:bg-transparent sm:w-[100px] w-full uppercase text-[10px] bg-blue-300 text-white-100  rounded-md  hover:bg-opacity-70">
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default CreateComplianceRuleModal;
